import logo from "./logo.svg";
import "./App.css";
import { InlineWidget } from "react-calendly";

const Header = () => {
  return (
    <div
      className="flex justify-between max-w-6xl	 mx-8 my-4"
      style={{
        margin: "1rem auto",
      }}
    >
      <div >
        {/* <span className="uppercase text-sm	 text-sky-700 tracking-wide	leading-3	font-bold">
          Healing with
        </span>{" "} */}
        {/* <br /> */}
        <span className="uppercase text-sm	 text-sky-700 tracking-wide	font-bold">
          Vaishnavi Verma
        </span>
      </div>
      {/* <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg">
        Book Now
      </button> */}
    </div>
  );
};

const Calendy = () => {
  return (
    <>
      <div className="text-center">
        <h2 className="text-3xl mt-4 font-bold text-sky-600">Book a session</h2>
      </div>
      {/* <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async ></script>

      <div
        className="calendly-inline-widget"
        data-url="https://calendly.com/vaishnaviverma154"
        style={{ minWidth: "320px", height: "700px" }}
      ></div> */}
      <InlineWidget url="https://calendly.com/vaishnaviverma154" />
    </>
  );
};

const Hero = () => {
  return (
    <div
      className="grid grid-cols-2 gap-4 max-w-6xl	 mx-2 my-4"
      style={{
        margin: "1rem auto",
      }}
    >
      <div>
        <img
          src="./profile.png"
          alt="Vaishnavi"
          style={{
            width: "80%",
          }}
        />
      </div>
      <div className="flex items-center	">
        
        <div
          style={{
            margin: "auto 0px",
          }}
        >
          <h1
            className="text-5xl	font-semibold text-sky-500 text-left			"
            style={{
              color: "#0081A7",
            }}
          >
            Helping people heal
          </h1>
          <p
            className="text-xl	 text-sky-500 my-4	text-left			"
            style={{
              color: "#0081A7",
            }}
          >
            Trauma focussed psychotherapist working with diverse mental health
            issues such as complex trauma, PTSD, depression, anxiety,
            relationship issues, and more.
          </p>
          <button className="bg-sky-500 hover:bg-sky-400 text-white font-bold py-2 px-4 rounded-lg mt-2">
            Book a session
          </button>
        </div>
      </div>
    </div>
  );
};
function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Calendy />
    </div>
  );
}

export default App;
